#register.modal.open{
    transform: translateX(0%);
    opacity: 1;
}


#register{
    
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #FFF;
    color: #FFF;
    
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.4s ease-in-out;

    padding-top: 8.5em;    


    button.close{
        position: absolute;
        right: 1.5em;
        top: 1.5em;
        background: none;
        border: none;
        box-shadow: none;
        display: inline-block;
        cursor: pointer;

        &:hover,
        &:focus{
            outline: none;
            border: none;
            box-shadow: none;
        }

        span{
            font-size: 6em;
            font-weight: 100;
            color: #FFF;
            display: block;
            line-height: 0.5;
            height: 0.7em;
        }

    }

    img{
        position: fixed;
        top: 1.5em;
        left: 1.5em;
        width: 6em;
        height: auto;
    }

    .modal--footer{
        width: 100%;
        text-align: center;

        span{
            display: block;
        }

        a{
            color: #FFF;
            text-decoration: none;

            &:hover,
            &:focus{
                text-decoration: underline;
            }
        }
    }

}
body {
    background: url(../img/caesarstone-seasons-bg.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: $lt-text;
}

// main contents
main {
    .container {
        max-width: 900px;
    }
    #logo {
        max-width: 26rem;
    }
    h1 {
        font-size: 2.2rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    p {
        font-size: 1.3rem;
        line-height: 1.9rem;
        &.smaller {
            font-size: 1.1rem;
            line-height: 1.6rem;
        }
    }
}

form {
    input.form-control {
        line-height: 3.2rem;
        height: 3.2rem;
        border-radius: 0px;
        font-size: 1.5rem;
        &::placeholder {
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
        }
        &:focus {
            color: #000;
            background-color: #fff;
            border-color: #474747;
            box-shadow: 0 0 0 0.2rem rgba(90, 90, 90, 0.25);
        }
    }
    .form-check-label, .col-form-label {
        line-height: 1.8rem;
        font-size: 1.2rem;
    }
    label.form-check-label[for="signUpToNewsletter"] {
      font-size: 0.8rem;
    }
    button[type='submit'] {
        border-radius: 0px;
        padding: 0.6rem 5.5rem;
        border: 2px solid #000;
        color: #000;
        font-weight: bold;
        letter-spacing: 0.2rem;
        &:hover {
            background-color: #000;
        }
    }
    .form-check {
      padding-left: 0px;
    }
    #email-fail {
      position: absolute;
      z-index: 1000;
      width: 98.5%;
      p {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }
}

.thank-you {
  display: none;
  h2 {
    line-height: 2rem;
  }
}

// mobile

@media only screen and (max-height: 1200px) {
  .pb-5 {
    padding-bottom: 1.6rem !important;
  }
  .pt-5 {
    padding-top: 1.6rem !important;
  }
  .py-5 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important;
  }
  .mb-4, .my-4 {
    padding-bottom: 1rem !important;
  }
  main {
    .container {
      max-width: 670px;
    }
    #logo {
      max-width: 13rem;
    }
    h1 {
      font-size: 1.3rem;
    }
    h2 {
      font-size: 1.1rem;
    }
    p {
      font-size: 0.9rem;
      line-height: 1.4rem;
      &.smaller {
        font-size: 0.8rem;
        line-height: 1.1rem;
      }
    }
  }

  form {
    input.form-control {
      line-height: 2.4rem;
      height: 2.4rem;
      border-radius: 0px;
      font-size: 0.9rem;
      &::placeholder {
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
      }
      &:focus {
        color: #000;
        background-color: #fff;
        border-color: #474747;
        box-shadow: 0 0 0 0.2rem rgba(90, 90, 90, 0.25);
      }
    }
    .form-check-label, .col-form-label {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }
    button[type='submit'] {
      border-radius: 0px;
      padding: 0.4rem 4.5rem 0.4rem !important;
      border: 2px solid #000;
      color: #000;
      font-weight: bold;
      letter-spacing: 0.2rem;
      &:hover {
          background-color: #000;
      }
    }
    .mb-4 {
      margin-bottom: 0px !important;
    }
  }
}

@media (max-width: 768px) {
  main {
    h1 {
      font-size: 1.9rem;
    }
    h2 {
      font-size: 1.4rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      &.smaller {
        font-size: 0.9rem;
        line-height: 1.4rem;
      }
    }
  }
  form {
    .pr-2, .pl-2, .mb-4 {
      padding: 0rem !important;
      margin-bottom: 0px !important;
    }
    input {
      &:not(.circular-radio) {
        min-height: 3rem;
      }
      height: 3rem;
      margin-bottom: 0.5rem;
    }
  }
  .mobile-label {
    input {
      top: -0.5rem;
    }
    label {
      width: 250px;
      text-align: left;
    }
  }
}
// global vars
$lt-text: #5b5a5d;

// global classes
.vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    @media only screen and (max-height: 850px), screen and (max-width: 768px) {
      all: inherit;
    }
}

.circular-radio {
  -webkit-appearance: none;
  background-color: #FFF;
  border-radius: 50px;
  border-width: 0;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  height: 1rem;
  margin: 0 7px 0 0;
  outline: none;
  padding: 0;
  position: relative;
  top: 0.2rem;
  width: 1rem;
  &:checked {
    background-color: #333;
  }
}

.mobile-br {
  display: none;
  @media only screen and (max-width : 768px) {
    .mobile-br {
      display: block !important;
    }
  }
}